/* eslint-disable jsx-a11y/tabindex-no-positive */
/* eslint-disable jsx-a11y/no-redundant-roles */
import React from 'react';
import { Link } from 'gatsby';
import { myStyles } from './styles';

export default function HeaderLogo({ primaryLogo }) {
  const classes = myStyles();
  return (
    <div id="header-section-logo" className={classes.HeaderLogoSection}>
      <Link id="header-section-logo-link" to="/" role="link" tabIndex="0">
        <img id="header-img" src={`https:${primaryLogo.file.url}`} alt={primaryLogo?.description} className={classes.HeaderLogo} />
      </Link>
    </div>
  );
}
