/* eslint-disable max-len */
import React, { useContext, useState, useEffect } from 'react';
import { Typography, Container, useMediaQuery } from '@material-ui/core';
import { createTheme } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import Box from '@material-ui/core/Box';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
// eslint-disable-next-line import/no-extraneous-dependencies
import MenuIcon from '@material-ui/icons/Menu';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
// import RestartAltIcon from '@material-ui/core/RestartAlt';
import Button from '@material-ui/core/Button';
import { navigate } from 'gatsby';
import clsx from 'clsx';
// import { container, classOne } from './header.module.css';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import HeaderButton from './headerButton';
import HeaderMenu from './headerMenu';
import HeaderLogo from './headerLogo';
import { myStyles } from './styles';
import ContactDialogs from '../contact-us';
import Search from './search';
import { themeStyles } from '../../styles';
import ThemeContext from '../../utils/theme-context';

const theme = createTheme({
});

export default function Header({
  navigation, contactNumber, toggle, setToggle, siteMetadata, contactForm, logout, astData, siteType, isSearchEnabled, isAst, isLeadForm, leadFormDetails, isLastScreen
}) {
  const partnerTheme = useContext(ThemeContext);
  const globalThemeCss = themeStyles(partnerTheme);
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const classes = myStyles(partnerTheme);
  const [open, setOpen] = React.useState(false);
  const toggleMenu = () => {
    setToggle(!toggle);
  };
  useEffect(() => {
    if(toggle){
      document.getElementById('close-hambarger-menu').focus()
    } 
    document?.getElementById('open-hambarger-menu')?.focus()
  }, [toggle]);
  const customHeader = !!isLeadForm;

  const handleClickOpen = () => {
    if (!isLastScreen) {
      setOpen(true);
    } else {
      setOpen(false);
      localStorage.removeItem('lead');
      localStorage.removeItem('sellApt');
      localStorage.removeItem('rewards');
      localStorage.removeItem('mobile');
      localStorage.removeItem('client-details');
      localStorage.removeItem('mongoContactId');
      localStorage.removeItem('pageLength');
      localStorage.removeItem('utm');
      localStorage.removeItem('buyLocation');
      localStorage.removeItem('sellLocation');
      navigate('/');
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleExit = () => {
    setOpen(false);
    localStorage.removeItem('lead');
    localStorage.removeItem('sellApt');
    localStorage.removeItem('rewards');
    localStorage.removeItem('mobile');
    localStorage.removeItem('client-details');
    localStorage.removeItem('mongoContactId');
    localStorage.removeItem('pageLength');
    localStorage.removeItem('utm');
    localStorage.removeItem('buyLocation');
    localStorage.removeItem('sellLocation');
    navigate('/');
  };

  const handleCallback = (menuFlag) => {
    setToggle(menuFlag);
  };

  return (
    <Container id="header-section" className={classes.headerContent}>
      <Typography component="header">
        {!isMobile ? (
          <div className={classes.InnerContainer}>
            <div className={classes.headerRight} style={{ flex: '1 1 auto' }}>
              <HeaderLogo primaryLogo={navigation.primaryLogoHeader} siteTypeLogo={siteType} />
              {navigation.menus && !customHeader && <HeaderMenu menuData={navigation.menus} headerButton={navigation.buttonAction} logout={logout} ast={astData} siteType={siteType} isAst={isAst} leadFormDetails={leadFormDetails}  />}
            </div>
            <div className={classes.headerLeft}>
              {isSearchEnabled && !customHeader && <Search />}
              { contactForm && !customHeader && (
              <ContactDialogs metadata={siteMetadata} contactFormDetails={contactForm} />
              )}
              {customHeader
              && (
                <div>
                  {contactNumber
                    && (
                    <grid id="callus-header" style={{ display: 'inline-flex' }}>
                      <Typography className={globalThemeCss.contactNum}>
                        {documentToReactComponents(JSON.parse(contactNumber.raw))}
                      </Typography>
                    </grid>
                    )}
                  <Tooltip arrow title="Exit" placement="bottom">
                    <IconButton aria-label="Close" className={classes.CloseBold} onClick={handleClickOpen}>
                      <CloseIcon />
                    </IconButton>
                  </Tooltip>
                  <Dialog
                    open={open}
                    onClose={handleClose}
                    // aria-labelledby="alert-dialog-title"
                    //aria-describedby="alert-dialog-description"
                    role="dialog"
                    aria-modal="true"
                    PaperProps={{
                      role:false
                    }}
                    TransitionProps={{
                      role:false
                    }}
                    aria-label="confirm exit"
                  >
                    <DialogContent>
                      <DialogContentText id="alert-dialog-description">
                        <Typography variant="h3" id="alert-dialog-title" className={classes.internalSubText}>
                          Exiting will clear your answers and take you back to the home page.
                        </Typography>
                      </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                      <Box display="flex" flexDirection="row" justifyContent="center" alignItems="center" flex="auto">
                        <Button size="large" className={clsx(globalThemeCss.outlinedBtn, classes.nextButton)} variant="outlined" onClick={handleExit}>Leave</Button>
                        <Button size="large" className={clsx(globalThemeCss.containedBtn, classes.nextButton)} variant="contained" onClick={handleClose} autoFocus>Stay</Button>
                      </Box>
                    </DialogActions>
                  </Dialog>
                </div>
              )}
            </div>
          </div>
        ) : (
          <div>
            <div
              className={clsx(
                classes.InnerContainer,
                classes.InnerContainerRes
              )}
            >
              {!toggle && (
                <Box
                display="flex"
                flexDirection="row"
                justifyContent="space-between"
                alignItems="center"
                flex="auto"
              >
                <HeaderLogo primaryLogo={navigation.primaryLogoHeader} />
                <div className={classes.headerClose}>
                  {customHeader
                  && (
                    <>
                      {contactNumber
                        && (
                        <grid id="callus-header" className={classes.headerCallUs}>
                          <Typography variant="body" className={globalThemeCss.contactNum}>
                            {documentToReactComponents(JSON.parse(contactNumber.raw))}
                          </Typography>
                        </grid>
                        )}
                      <Tooltip arrow title="Exit" placement="bottom">
                        <IconButton aria-label="Close" className={classes.CloseBold} onClick={handleClickOpen}>
                          <CloseIcon />
                        </IconButton>
                      </Tooltip>
                      <Dialog
                        open={open}
                        onClose={handleClose}
                        // aria-labelledby="alert-dialog-title"
                        //aria-describedby="alert-dialog-description"
                        role="dialog"
                        PaperProps={{
                          role:false
                        }}
                        TransitionProps={{
                         role:false
                        }}
                        aria-modal="true"
                        aria-label="confirm exit"
                      >
                        <DialogContent>
                          <DialogContentText id="alert-dialog-description">
                            <Typography variant="h3" id="alert-dialog-title" className={classes.internalSubText}>
                              Exiting will clear your answers and take you back to the home page.
                            </Typography>
                          </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                          <Box display="flex" flexDirection="row" justifyContent="center" alignItems="center" flex="auto">
                            <Button size="large" className={clsx(globalThemeCss.outlinedBtn, classes.nextButton)} variant="outlined" onClick={handleExit}>Leave</Button>
                            <Button size="large" className={clsx(globalThemeCss.containedBtn, classes.nextButton)} variant="contained" onClick={handleClose} autoFocus>Stay</Button>
                          </Box>
                        </DialogActions>
                      </Dialog>
                    </>
                  )}
                  {(!customHeader && !toggle)
                  && (
                    <Button id="open-hambarger-menu" aria-label= "menu" onClick={() => toggleMenu()}>
                      <MenuIcon />
                    </Button>
                  )}
                </div>
              </Box>
                )}
              
            {toggle &&(
              <div aria-label="navigation" aria-modal="true" role="dialog" className={toggle ? classes.container : clsx(classes.classOne, classes.hideHamburger)}>
              <Box
                      display="flex"
                      flexDirection="row"
                      justifyContent="space-between"
                      alignItems="flex-start"
                    >
                      <IconButton aria-label="close" id="close-hambarger-menu" className={classes.CloseButton} fontSize="large" onClick={() => toggleMenu()}>
                      <CloseIcon />
                    </IconButton >

                      <div className={classes.menuWhiteSection}>
                        <HeaderButton headerButton={navigation.buttonAction} logout={logout} ast={astData} siteType={siteType} />
                        { contactForm && <ContactDialogs metadata={siteMetadata} contactFormDetails={contactForm} /> }
                        {isSearchEnabled && <Search />}
                        {navigation.menus && <HeaderMenu menuData={navigation.menus} parentCallback={handleCallback} />}
                      </div>
                    </Box>              
              </div>
            )}
            </div>
          </div>
        )}
      </Typography>
    </Container>
  );
}
